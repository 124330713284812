<template>
  <!-- <DarkModeSwitcher /> -->
  <div class="container sm:px-10">
    <div class="block xl:grid grid-cols-2 gap-4">
      <!-- BEGIN: Login Info -->
      <div class="hidden xl:flex flex-col min-h-screen">
        <a href="" class="-intro-x flex items-center pt-5">
          <!-- <img
            :alt="adminTitle"
            class="w-6"
            :src="require(`@/assets/images/cloudfun.svg`)"
          /> -->
          <span class="text-white text-lg ml-3">
            <!-- {{ adminTitle }} -->
            蕭氏貿易後台管理系統
          </span>
        </a>
        <div class="my-auto">
          <img
            alt="蕭氏貿易後台管理系統"
            class="-intro-x w-1/2 -mt-16"
            :src="require(`@/assets/images/logo.svg`)"
          />
          <!-- <div
            class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
          >
            A few more clicks to <br />
            sign in to your account.
          </div>
          <div class="-intro-x mt-5 text-lg text-white dark:text-gray-500">
            Manage all your e-commerce accounts in one place
          </div> -->
        </div>
      </div>
      <!-- END: Login Info -->
      <!-- BEGIN: Login Form -->
      <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
        <div
          class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
        >
          <h2
            class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
          >
            登入
          </h2>
          <!-- <div class="intro-x mt-2 text-gray-500 xl:hidden text-center">
            A few more clicks to sign in to your account. Manage all your
            e-commerce accounts in one place
          </div> -->
          <form @submit.prevent="formSubmit()">
            <div class="intro-x mt-8">
              <input
                ref="headline"
                type="text"
                class="intro-x login__input input input--lg border border-gray-300 block"
                placeholder="帳號"
                v-model="account"
              />
              <input
                type="password"
                class="intro-x login__input input input--lg border border-gray-300 block mt-4"
                placeholder="密碼"
                v-model="password"
              />
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <input type="hidden" v-model="captchaToken" />
              <input
                type="text"
                class="intro-x input input--lg border border-gray-300 block"
                placeholder="驗證碼"
                v-model="captcha"
              />
              <img class="pl-4" :src="captchaUrl" @click="reloadCaptcha()" />
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <div class="flex items-center mr-auto">
                <input
                  id="remember-me"
                  type="checkbox"
                  class="input border mr-2"
                  v-model="rememberMe"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >記住我</label
                >
              </div>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                type="submit"
                class="button button--lg w-full xl:w-32 text-white bg-theme-1 xl:mr-3 align-top"
              >
                登入
              </button>
            </div>
          </form>
          <!-- <div
            class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"
          >
            By signin up, you agree to our <br />
            <a class="text-theme-1 dark:text-theme-10" href=""
              >Terms and Conditions</a
            >
            &
            <a class="text-theme-1 dark:text-theme-10" href=""
              >Privacy Policy</a
            >
          </div> -->
        </div>
      </div>
      <!-- END: Login Form -->
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
// import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import { mapActions } from "vuex";
import CloudFun from "@cloudfun/core";
import { v1 as uuid } from "uuid";

export default defineComponent({
  components: {
    // DarkModeSwitcher,
  },
  setup() {
    const captchaToken = ref(uuid());
    const captchaUrl = computed(
      () =>
        `${process.env.VUE_APP_BACKEND_URL}/api/captcha?token=${captchaToken.value}`
    );
    // const adminTitle = computed(() => CloudFun.current?.model.state.configuration.value.AdminTitle || "蕭氏貿易管理後台")

    onMounted(async () => {
      cash("body")
        .removeClass("app")
        .removeClass("error-page")
        .addClass("login");
      cash("body")[0].click();
    });
    return {
      // adminTitle,
      account: "",
      password: "",
      captcha: "",
      captchaToken,
      captchaUrl,
      rememberMe: false
    };
  },
  methods: {
    ...mapActions(["login"]),
    formSubmit() {
      this.login({
        account: this.account,
        password: this.password,
        rememberMe: this.rememberMe,
        captcha: this.captcha,
        captchaToken: this.captchaToken
      }).then(
        () => this.$router.push("/"),
        failure => {
          this.$user = CloudFun.guest;
          this.reloadCaptcha();
          CloudFun.send("warning", failure.message || "帳號或密碼錯誤");
        }
      );
    },
    reloadCaptcha() {
      this.captchaToken = uuid();
    }
  }
});
</script>
